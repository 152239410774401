import { autorun, makeAutoObservable } from "mobx";
import { toJS } from 'mobx'
import { useHistory } from 'react-router'
import { once, sort } from "ramda";
import axios from "axios";



class RootStore {

    constructor() {
        makeAutoObservable(this)
    }
    history: ReturnType<typeof useHistory> | null = null
    user: api.IUser | null = null
    get role() {
        return this.user?.role
    }
    logout() {
        this.user = null
    }
}

const rootStore = new RootStore()

export default rootStore

autorun(() => {
    (window as any).rootStore = toJS(rootStore)
})