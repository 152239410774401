import { Spin } from 'antd';
import { toJS } from 'mobx';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { Global, css } from '@emotion/core';
import rootStore from './store/rootStore';
import { observer } from 'mobx-react';
import { message } from 'antd';
import axios from 'axios';


const Layout = lazy(() => import('./Layout'))
window.toJS = toJS;
window.React = React;

axios.defaults.timeout = 60000;
axios.defaults.withCredentials = false;
axios.interceptors.response.use((response) => {
    if (typeof response.data === 'object' && typeof response.data?.message === 'string' && response.data?.message) {
        if (response.data.code) message.error(response.data.message)
        else message.success(response.data.message)
    }
    return response;
}, (error) => {
    const errMsg = (error.response && error.response.data && error.response.data.message) || error.toString();
    const code = (error.response && error.response.data && error.response.data.code) || 'UNDEFINED';
    // tslint:disable-next-line:no-console
    console.error(`--axios请求报错，请求地址：${error.config.url}\n`, `--请求body：`, (error.config && error.config.data) || error.response);
    message.error(errMsg);

    if ([60007, 60008].includes(code)) {
        console.log(window.location.href, window.location.href.includes('/loginByToken'))
        if (!window.location.href.includes('/loginByToken')) window.location.href = '/login';
    }
    return Promise.reject(error);
});

const Loading = () => (
    <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Spin />
    </div>
)

const App: React.FC = observer(() => {
    return (
        <ErrorBoundary>
            <Global styles={css`
                html, body{
                    @keyframes feadIn {
                        from{
                            opacity: 0;
                        }
                        to{
                            opacity: 1
                        }
                    }
                    .mainContent>div{
                        animation: feadIn 500ms both;
                    }
                }
            `}></Global>
            <Suspense fallback={<Loading />}>
                <BrowserRouter>
                    <History />
                    <div className="mainContent">
                        <Switch>
                            <Route exact path="/login" component={lazy(() => import('./login'))}></Route>
                            <Route exact path="/liweiTest" component={lazy(() => import('./liweiTest'))}></Route>
                            <Route exact path="/test" component={lazy(() => import('./test'))}></Route>
                            <Route path="*">
                                <Layout>
                                    <Suspense fallback={<Loading />}>
                                        <div className="mainContent">
                                            <Switch>
                                                {rootStore.role === 'admin' && [
                                                    <Route exact key={1} path="/adx" component={lazy(() => import('./adx/index'))}></Route>,

                                                    <Route exact key={2} path="/country" component={lazy(() => import('./country'))}></Route>,
                                                    <Route exact key={3} path="/media" component={lazy(() => import('./media'))}></Route>,
                                                    <Route exact key={3} path="/source" component={lazy(() => import('./source'))}></Route>,
                                                    <Route exact key={4} path="/income" component={lazy(() => import('./income'))}></Route>,
                                                    <Route exact key={5} path="/settlement" component={lazy(() => import('./settlement'))}></Route>,
                                                    <Route exact key={6} path="/settlement" component={lazy(() => import('./settlement'))}></Route>,
                                                    <Route exact path="/user" key={'111'} component={lazy(() => import('./user'))}></Route>,
                                                    <Route exact path="/user/add" key={'112'} component={lazy(() => import('./user/add'))}></Route>,
                                                    <Route exact path="/user/:id" key={'113'} component={lazy(() => import('./user/add'))}></Route>,
                                                    <Route exact path="/log" key={11} component={lazy(() => import('./log'))}></Route>,
                                                ]}
                                                <Route exact key={1} path="/adp" component={lazy(() => import('./adp/index'))}></Route>
                                                <Route exact key={1} path="/adp/add" component={lazy(() => import('./adp/edit'))}></Route>
                                                <Route exact key={1} path="/adp/:_id" component={lazy(() => import('./adp/edit'))}></Route>
                                                {/* <Route exact key={1} path="/adp/list" component={lazy(() => import('./adp/list'))}></Route>, */}
                                                <Route exact key={1} path="/dataReport" component={lazy(() => import('./dataReport'))}></Route>,
                                                <Route exact key={1} path="/afs" component={lazy(() => import('./afs/index'))}></Route>,
                                                <Route exact key={1} path="/financeReport" component={lazy(() => import('./financeReport'))}></Route>
                                                <Route exact path="/me" component={lazy(() => import('./user/me'))}></Route>
                                                <Route path='*' component={lazy(() => import('./404'))} />
                                            </Switch>
                                        </div>
                                    </Suspense>
                                </Layout>
                            </Route>
                        </Switch>
                    </div>
                </BrowserRouter>
            </Suspense>
        </ErrorBoundary>
    );
});

export default App;


const History = () => {
    rootStore.history = useHistory()
    return null
}
class ErrorBoundary extends React.Component {
    public state = {
        hasError: false,
        error: '',
    };

    public componentDidCatch(error: any, info: any) {
        this.setState({
            hasError: true,
            error,
        });
        console.log('ErrorBoundary!!!');
    }

    public render() {
        if (this.state.hasError && (this.state.error as any)?.status === '900009')
            return (
                <div>
                    <h1>登录过期：{this.state.error.toString()}</h1>
                    <div style={{ cursor: 'pointer', color: '#44aa88' }} onClick={() => window.location.href = '/login'}>点击重新登录</div>
                </div>
            )
        if (this.state.hasError) {
            return <div>
                <h1>发生错误：{this.state.error.toString()}</h1>
                <div style={{ cursor: 'pointer', color: '#44aa88' }} onClick={() => window.location.reload()}>点击刷新尝试修复</div>
            </div>;
        }
        return this.props.children;
    }
}
