import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'
import { ConfigProvider } from 'antd';
import { configure } from 'mobx'
import { observer } from 'mobx-react';
import './style/index.css'
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';

configure({ enforceActions: 'never' })

const theme = {
    "token": {
        "colorPrimary": "#379bec",
        "colorSuccess": "#18634a",
        "colorError": "#bf4547",
        "borderRadius": 4,
        "wireframe": true
    }
}
const Index = observer(() => {
    return (
        <ConfigProvider theme={theme} locale={zhCN}>
            <App />
        </ConfigProvider>
    )
})

ReactDOM.render(
    <Index />,
    document.getElementById('root'));
